/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { MouseEvent } from "react";
import "./styles.css";
import {
  contentText,
  mainSection,
  truncText,
  aboutUs,
  storyImg,
  contactButton,
} from "./react-styles.css";
import { Container, NavLink, Row } from "reactstrap";
import aboutUsBackground from "./images/about-header.jpg";
import aboutUs1 from "./images/about-1.jpg";
import aboutUs2 from "./images/about-2.jpg";
import aboutUs3 from "./images/about-3.jpg";

type AboutUsProps = {};
type AboutUsState = {
  showMore: boolean;
};

class AboutUs extends React.Component<AboutUsProps, AboutUsState> {
  constructor(props: AboutUsProps) {
    super(props);
    this.state = {
      showMore: false,
    };
  }

  toggleShowMore(e: MouseEvent<any, any>) {
    e.preventDefault();
    this.setState({ showMore: !this.state.showMore });
  }

  render() {
    return (
      <div>
        <section css={mainSection(aboutUsBackground)}>
          <div css={contentText}>
            <h3>About Homevanna</h3>
            <div css={truncText}>
              <p>
                Homevanna is the place to find unique properties at fair prices
                - just a click away. By providing one of the largest online home
                auction{" "}
                <span className="show-text">
                  marketing platforms, Homevanna allows institutions to market
                  their inventory directly to serious buyers and investors. Our
                  smart tech and streamlined process make transactions faster
                  and easier for both buyers and sellers.
                </span>
                <span className="ellipse">...</span>
                <a
                  onClick={(event) => this.toggleShowMore(event)}
                  className="more-show"
                >
                  More
                </a>
              </p>
            </div>
          </div>
        </section>

        <section css={aboutUs}>
          <Container>
            <Row>
              <div className="col-lg-10 offset-lg-1">
                <div>
                  <div className="media align-items-center">
                    <div css={storyImg}>
                      <img src={aboutUs1} alt="" />
                    </div>
                    <div className="media-body">
                      <h4>Our Story</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                      <p>
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur.
                        Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                      </p>
                    </div>
                  </div>
                  <div className="media align-items-center">
                    <div css={storyImg}>
                      <img src={aboutUs2} alt="" />
                    </div>
                    <div className="media-body">
                      <h4>Our People</h4>
                      <p>
                        Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                      <p>
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur.
                      </p>
                    </div>
                  </div>
                  <div className="media align-items-center  text_short">
                    <div css={storyImg}>
                      <img src={aboutUs3} alt="" />
                    </div>
                    <div className="media-body">
                      <h4>Our Location</h4>
                      <p>
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur.
                        Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                      </p>
                      <NavLink
                        href={"#"}
                        role={"button"}
                        css={contactButton}
                        className={"btn"}
                      >
                        Contact Us
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export { AboutUs };
