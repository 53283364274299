/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { moreFilterAlt } from "../../Home/styles.css";
import { CustomCheckbox } from "../CustomCheckbox";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { baseDropdown } from "../../../css/common.css";

export function ListingStatus() {
  return (
    <Row css={moreFilterAlt}>
      <Col>
        <div
          className={
            "d-flex flex-wrap justify-content-between align-items-center"
          }
        >
          <h5>Status</h5>
          <UncontrolledDropdown
            id={"listingStatus"}
            css={baseDropdown}
            onToggle={(event: React.KeyboardEvent | React.MouseEvent) => {
              console.log(event.target);
            }}
          >
            <DropdownToggle caret={true}>Active + Coming Soon</DropdownToggle>
            <DropdownMenu>
              <DropdownItem>Active</DropdownItem>
              <DropdownItem>Pending</DropdownItem>
              <DropdownItem>Coming Soon</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="all-label">
          <CustomCheckbox id={"checkboxOpenHouse"} label={"Open House"} />
          <CustomCheckbox
            id={"checkboxExcludeShortSale"}
            label={"Exclude Short Sales"}
          />
          <CustomCheckbox
            id={"checkboxHomevannaOnly"}
            label={"Homevanna Listings Only"}
          />
        </div>
      </Col>
    </Row>
  );
}

export function ListingDuration() {
  return (
    <Row css={moreFilterAlt}>
      <Col>
        <div
          className={
            "d-flex flex-wrap justify-content-between align-items-center"
          }
        >
          <h5>Time on Homevanna</h5>
          <UncontrolledDropdown
            id={"TimeOnMarket"}
            css={baseDropdown}
            onToggle={(event: React.KeyboardEvent | React.MouseEvent) => {
              console.log(event.target);
            }}
          >
            <DropdownToggle caret={true}>No Min</DropdownToggle>
            <DropdownMenu>
              <DropdownItem>10 days</DropdownItem>
              <DropdownItem>30 days</DropdownItem>
              <DropdownItem>90 days</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        <div className="all-label">
          <CustomCheckbox id={"checkboxPriceReduced"} label={"Price Reduced"} />
          <CustomCheckbox
            id={"checkboxFixerOnly"}
            label={"Fixer-Uppers Only"}
          />
        </div>
      </Col>
    </Row>
  );
}
