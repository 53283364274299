import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";

@jsonObject
class SimpleListing {
  @jsonMember({ constructor: String })
  address: string;
  @jsonMember({ constructor: Number })
  bathroomsCount: number;
  @jsonMember({ constructor: Number })
  bedroomsCount: number;
  @jsonMember({ constructor: String })
  floodRisk: string;
  @jsonMember({ constructor: Number })
  hoaFee: number;
  @jsonArrayMember(String)
  images: string[];
  @jsonMember({ constructor: String })
  leaseTerm: string;
  @jsonMember({ constructor: String })
  listingId: string;
  @jsonMember({ constructor: Number })
  lotSize: number;
  @jsonMember({ constructor: String })
  occupancy: string;
  @jsonMember({ constructor: String })
  parcelId: string;
  @jsonMember({ constructor: Number })
  price: number;
  @jsonMember({ constructor: Number })
  size: number;
  @jsonMember({ constructor: Number })
  year: number;
  @jsonMember({ constructor: String })
  city: string;
  @jsonMember({ constructor: String })
  state: string;
  @jsonMember({ constructor: String })
  street: string;
  @jsonMember({ constructor: String })
  zip: string;
  @jsonMember({ constructor: Number })
  lat: number;
  @jsonMember({ constructor: Number })
  lon: number;
  constructor() {
    this.address = "";
    this.bathroomsCount = 0;
    this.bedroomsCount = 0;
    this.floodRisk = "low";
    this.hoaFee = 0;
    this.images = [];
    this.leaseTerm = "";
    this.listingId = "";
    this.lotSize = 0;
    this.occupancy = "none";
    this.parcelId = "";
    this.price = 0;
    this.size = 0;
    this.year = 2020;
    this.city = "";
    this.state = "";
    this.street = "";
    this.zip = "";
    this.lat = 0;
    this.lon = 0;
  }
}

export { SimpleListing };
