/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import { mainContent, mapArea, mapResultCount } from "./styles.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FilterPanel } from "./FiltersPanel";
import { ResultsList } from "./ResultsList";
import { ApiClient } from "../../utils/apiClient";
import { SimpleListing } from "../../data-models/SimpleListing";
import { Redirect } from "react-router-dom";
import { GoogleMap } from "../../components/Map/GoogleMap";
import { Coordinate } from "../../data-models/Shared";
import { PaginatedPropertiesResponse } from "../../data-models/PaginatedPropertiesResponse";

type PropertySearchProps = {
  query?: { [key: string]: any };
};
type PropertySearchStates = {
  fetchState: PropertySearchState;
  listings: SimpleListing[];
  page: number;
  totalPages: number;
  coordinates: Coordinate[];
  selectedListingId?: string;
  showResultListTiles: boolean;
};
enum PropertySearchState {
  loading,
  finished,
  missingQuery,
}

class PropertySearch extends React.Component<
  PropertySearchProps,
  PropertySearchStates
> {
  private apiClient: ApiClient;
  private readonly recordsPerPage: number;
  private currentQuery: string;

  constructor(props: PropertySearchProps) {
    super(props);
    this.apiClient = new ApiClient();
    this.recordsPerPage = 8;
    this.state = {
      listings: [],
      fetchState: PropertySearchState.loading,
      page: 1,
      totalPages: 0,
      coordinates: [],
      showResultListTiles: true,
    };
    this.currentQuery = "";
    library.add(fas);
  }

  navigateToNextPage = () => {
    let query = this.props.query;
    if (query == null) {
      return;
    }
    query["page"] = this.state.page + 1;
    this.apiClient.searchProperties(query).then((result) => {
      this.setPageState(result, this.state.page + 1);
    });
  };

  navigateToPage = (page: number) => {
    let query = this.props.query;
    if (query == null) {
      return;
    }
    query["page"] = page;
    this.apiClient.searchProperties(query).then((result) => {
      this.setPageState(result, page);
    });
  };

  private setPageState = (
    result: PaginatedPropertiesResponse,
    page: number
  ) => {
    let totalPages = Math.ceil(result.totalRecords / this.recordsPerPage);
    this.setState({
      listings: result.properties,
      fetchState: PropertySearchState.finished,
      totalPages: totalPages,
      page: page,
    });
  };

  componentDidMount() {
    if (
      this.props.query == null ||
      Object.keys(this.props.query).length === 0
    ) {
      this.setState({
        fetchState: PropertySearchState.missingQuery,
      });
      return;
    }
    let query = this.props.query;
    query["page"] = this.state.page;
    this.apiClient.searchProperties(query).then((result) => {
      this.setPageState(result, this.state.page);
    });
    this.currentQuery = query["address"];
  }

  listingSelected(listingId: string) {
    console.log(listingId);
    this.setState({
      selectedListingId: listingId,
    });
  }

  animateResultList(show: boolean) {
    this.setState({
      showResultListTiles: show,
    });
  }

  render() {
    if (
      this.props.query != null &&
      this.currentQuery != this.props.query["address"]
    ) {
      this.setState({
        page: 1,
        fetchState: PropertySearchState.loading,
      });
      this.currentQuery = this.props.query["address"];
      let query = this.props.query;
      query["page"] = this.state.page;
      this.apiClient.searchProperties(query).then((result) => {
        this.setPageState(result, this.state.page);
      });
    }
    let recordStart = Math.max(1, (this.state.page - 1) * this.recordsPerPage);
    let recordEnd = recordStart + this.state.listings.length - 1;
    switch (this.state.fetchState) {
      case PropertySearchState.missingQuery:
        return <Redirect to={"/"} />;
      case PropertySearchState.loading:
        return (
          <main css={mainContent}>
            <Spinner color={"dark"} size={"lg"}>
              Loading
            </Spinner>
          </main>
        );
      case PropertySearchState.finished:
        return (
          <main css={mainContent}>
            <div css={mapArea}>
              <Container fluid={true}>
                <Row>
                  <Col>
                    <div className={"h-100"}>
                      <div css={mapResultCount}>
                        <p>{`Showing ${recordStart} - ${recordEnd} of ${
                          this.state.totalPages * this.recordsPerPage
                        } properties`}</p>
                      </div>
                      <GoogleMap
                        listings={this.state.listings}
                        selectedProperty={this.listingSelected.bind(this)}
                      />
                    </div>
                  </Col>
                  <Col lg={4} xl={3}>
                    <FilterPanel
                      numberOfResults={
                        this.state.totalPages * this.recordsPerPage
                      }
                      showingStart={() => {
                        this.animateResultList(false);
                      }}
                      hidingStart={() => {
                        this.animateResultList(true);
                      }}
                    />
                    <ResultsList
                      isVisible={this.state.showResultListTiles}
                      listings={this.state.listings}
                      totalPage={this.state.totalPages}
                      currentPage={this.state.page}
                      nextPageHandler={this.navigateToNextPage}
                      skipPageHandler={this.navigateToPage}
                      selectedListingId={this.state.selectedListingId}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
            {/*map-area */}
          </main>
        );
    }
  }
}

export { PropertySearch };
