import React, { useContext } from "react";
import { Row, Col } from "reactstrap";
import Tooltip from "../../../../../components/Tooltip";
import {
  calcCapRate,
  calcGrossYield,
  calcLoanPaymentsValue,
} from "../../../../../utils/formulas";
import { displayNumber, PageContext } from "../../../../../utils";

import "./style.scss";
import * as EVENTS from "events";

const GrossYield = () => {
  const {
    rentData,
    expenses,
    propertyTaxes,
    purchasePrice,
    annualizedReturn,
    downPayment,
    loanInterestRate,
  } = useContext(PageContext)[0];
  const dispatch = useContext(PageContext)[1];

  const loanPayments = calcLoanPaymentsValue({
    purchasePrice,
    downPayment,
    loanInterestRate,
  });

  let rent = rentData == null ? 0 : rentData.mean;
  const capRate = calcCapRate(
    rent,
    expenses,
    propertyTaxes,
    purchasePrice,
    loanPayments
  );

  const grossYield = calcGrossYield({
    expectedRent: rent,
    purchasePrice: purchasePrice,
  });

  const handleRestoreDefault = () => {
    dispatch({ type: EVENTS.RESTORE_DEFAULT });
  };

  return (
    <div className="GrossYield card-box">
      <Row>
        <Col xs="6" sm="4" md="3" lg="2">
          <div className="section">
            <div>
              Cap Rate
              <Tooltip context="CapRate" />
            </div>
            <span>{capRate}%</span>
          </div>
        </Col>

        <Col xs="6" sm="4" md="3" lg="2">
          <div className="section">
            <div>
              Gross yield
              <Tooltip context="GrossYield" />
            </div>
            <span>{grossYield}%</span>
          </div>
        </Col>

        <Col xs="6" sm="4" md="3" lg="2">
          <div className="section">
            <div>
              CASH ON CASH
              <Tooltip context="CashOnCash" />
            </div>
            <span>9.6%</span>
          </div>
        </Col>

        <Col xs="6" sm="4" md="3" lg="2">
          <div className="section">
            <div>
              ANN. RETURN
              <Tooltip context="AnnReturn" />
            </div>
            <span>
              {displayNumber(annualizedReturn)}%
              <span className="lower-text">in 5 Years</span>
            </span>
          </div>
        </Col>

        <Col md="12" lg="4">
          <button onClick={handleRestoreDefault} className="btn-blue">
            Restore to Default
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default GrossYield;
