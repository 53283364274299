import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { baseFont } from "../../css/common.css";
import { NavbarBrand } from "reactstrap";

const navFont = css(baseFont, {
  color: "#fff",
  textTransform: "uppercase",
  fontSize: "17px",
  fontWeight: 600,
});

const navLink = css(navFont, {
  padding: "5px 4px",
  margin: "10px 20px",
  transition: "0.3s ease all",
  "&: hover": {
    color: "#f29c1a !important",
  },
  "&: before": {
    background: "#ff9900",
    height: "2.5px",
  },
});

const navButton = css(navFont, {
  backgroundColor: "#FF9900",
  borderColor: "#FF9900",
  width: "245px",
  boxShadow: "-1px 1px 20px rgba(0, 0, 0, 0.22)",
  margin: "8px 3px",
  "&:hover": {
    background: "#f29c1a",
    borderColor: "#f29c1a",
  },
});

const navBar = css({
  padding: 0,
  transition: "0.3s ease all",
  zIndex: 999,
});

const navBarScrolled = css(navBar, {
  backgroundColor: "#fff",
  ".nav-link": {
    color: "#000",
  },
  ".btn-primary": {
    color: "#fff",
  },
});

const collapsedMenu = css({
  width: "320px",
  background: "rgb(98,111,148)",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 99999999999,
  padding: "15px",
  transition: "0.5s ease all",
});

const headerSearchBox = css({
  width: "360px",
  "@media screen and (max-width: 1200px) and (min-width: 992px)": {
    width: "315px",
  },
  "@media screen and (max-width: 767px)": {
    width: "150px",
  },
  "@media screen and (max-width: 767px) and (min-width: 576px)": {
    width: "335px",
  },
});

const headerSearchButton = css({
  backgroundColor: "#f29c1a",
  width: "auto",
  fontSize: "1rem !important",
  boxShadow: "unset !important",
  border: "none",
});

const mobileMenuToggle = css({
  color: "white",
  background: "transparent",
  border: 0,
  fontSize: "27px",
  transition: "0.3s ease all",
});

const desktopNavItem = css({
  "@media screen and (max-width: 768px)": {
    display: "none !important",
  },
});

const headerBackground = css({
  "@media (max-width: 576px)": {
    backgroundColor: "rgb(0, 51, 153)",
    background:
      "linear-gradient(\n" +
      "      180deg,\n" +
      "      rgba(0, 51, 153, 0) 0%,\n" +
      "      rgba(0, 51, 153, 1) 100%\n" +
      ")",
  },
});

const navBarMobile = css(headerBackground, {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  padding: "10px 20px",
  zIndex: 99999999,
  transition: "0.3s ease all",
  "@media screen and (max-width: 992px)": {
    display: "block",
  },
  "@media screen and (min-width: 993px)": {
    display: "none",
  },
});

const navBarLogoText = css({
  display: "inline-block",
  transition: "0.3s ease all",
  fontSize: 16,
  img: {
    maxWidth: 24,
  },
  span: {
    paddingLeft: 5,
  },
  marginRight: "1rem",
  ":hover": {
    color: "#fff",
    textDecoration: "none",
  },
});

const navBarLogoTextNormal = css(navBarLogoText, {
  span: { color: "#fff" },
});

const navBarLogoTextScrolled = css(navBarLogoText, {
  span: { color: "#000" },
});

const StyledNavBarBrand = styled(NavbarBrand)({
  paddingLeft: 10,
  transition: "0.3s ease all",
});

export {
  navBar,
  navLink,
  navButton,
  collapsedMenu,
  headerSearchBox,
  headerSearchButton,
  mobileMenuToggle,
  desktopNavItem,
  navBarMobile,
  navBarScrolled,
  navBarLogoTextNormal,
  navBarLogoTextScrolled,
  StyledNavBarBrand,
};
