import { css } from "@emotion/core";
import { baseSection, primaryButton } from "../../css/common.css";
import { baseFont } from "../../css/common.css";

const mainSection = (backgroundImg: string) => {
  return css(baseFont, baseSection, {
    background: `url(${backgroundImg})`,
    h1: {
      fontSize: "63px",
      marginBottom: "30px",
    },
    h4: {
      fontSize: "31px",
      maxWidth: "600px",
      margin: "auto",
      marginBottom: "20px",
    },
    paddingBottom: 0,
    height: "90vh",
    minHeight: "90vh",
    display: "flex",
    alignItems: "flex-end",
  });
};

const contentText = css({
  maxWidth: 744,
  margin: "0 auto 85px",
  textAlign: "center",
  h3: {
    fontSize: "64px",
    color: "#D7D3CC",
    fontWeight: 400,
  },
  p: {
    color: "#fff",
    fontSize: "22px",
    lineHeight: "28px",
    fontWeight: 300,
  },
});

const truncText = css({
  ".more-show": {
    display: "none",
  },
  "span.ellipse": {
    display: "none",
  },
});

const aboutUs = css(baseFont, {
  backgroundColor: "#F9E6C8",
  padding: "40px 0 73px",
  ".media": {
    marginBottom: 40,
    "&:nth-child(even)": {
      flexDirection: "row-reverse",
    },
  },
  ".media-body": {
    h4: {
      fontSize: "32px",
      lineHeight: "40px",
      color: "#000",
      marginBottom: 8,
    },
    p: {
      color: "#383F44",
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 400,
      marginBottom: 24,
      "&:last-of-type": {
        marginBottom: 0,
      },
    },
    ".btn-primary": {
      fontSize: "14px",
      width: "auto",
      padding: "2.5px 16px",
      minWidth: 98,
      marginTop: 27,
      boxShadow: "none",
    },
  },
});

const signupModal = css({
  ".modal-dialog": {
    maxWidth: "816px",
  },
});

const storyImg = css({
  width: 320,
  height: 320,
  borderRadius: "50%",
  marginRight: "40px",
  marginLeft: "40px",
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    objectFit: "cover",
  },
});

const contactButton = css(primaryButton, {
  fontSize: "14px",
  width: "auto",
  padding: "2.5px 16px",
  minWidth: "98px",
  marginTop: "27px",
  boxShadow: "none",
  display: "inline-block",
});

export {
  mainSection,
  contentText,
  truncText,
  aboutUs,
  storyImg,
  signupModal,
  contactButton,
};
