import React, { useContext, useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem } from "reactstrap";
import Slider from "rc-slider";
import Tooltip from "../../../../components/Tooltip";
import {
  getMinPurchasePrice,
  getMaxPurchasePrice,
} from "../../../../utils/formulas";
import { PageContext, displayNumber } from "../../../../utils";
import {
  MIN_DOWNPAYMENT_VALUE,
  MAX_DOWNPAYMENT_VALUE,
} from "../../../../utils/config";

import "rc-slider/assets/index.css";
import "./style.scss";
import { EVENTS } from "../../../../utils/Context/reducer";

const PriceParams = () => {
  const {
    price,
    downPayment,
    purchasePrice,
    rentData,
    estimatedMarketRent,
  } = useContext(PageContext)[0];
  const dispatch = useContext(PageContext)[1];

  const handlePurchasePriceChange = (value) => {
    dispatch({ type: EVENTS.PURCHASE_PRICE_CHANGE, payload: value });
    dispatch({ type: EVENTS.SET_NET_CASH_FLOW });
  };

  const handleDownPaymentChange = (value) => {
    dispatch({ type: EVENTS.DOWNPAYMENT_CHANGE, payload: value });
    dispatch({ type: EVENTS.SET_NET_CASH_FLOW });
  };

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  let priceSliderValues = [];
  let startPrice = getMinPurchasePrice(price);
  let finishPrice = getMaxPurchasePrice(price);
  priceSliderValues.push(startPrice);
  let lowerPercentage = startPrice / purchasePrice;
  let higherPercentage = finishPrice / purchasePrice;
  let count = (higherPercentage - lowerPercentage) / 0.02;
  for (let i = 0; i < count; i++) {}
  priceSliderValues.push(finishPrice);
  useEffect(() => {
    handlePurchasePriceChange(price);
  }, [price]);

  return (
    <div className="PriceParams">
      <div className="section">
        <div className="title flex">
          Purchase Price
          <Tooltip context="PurchasePrice" />
          <span>${displayNumber(purchasePrice)}</span>
        </div>
        <Slider
          value={purchasePrice}
          onChange={handlePurchasePriceChange}
          min={getMinPurchasePrice(price)}
          max={getMaxPurchasePrice(price)}
          // defaultValue={purchasePrice}
        />
      </div>

      <div className="section">
        <div className="title flex">
          Down Payment
          <Tooltip context="DownPayment" />
          <span>{downPayment}%</span>
        </div>
        <Slider
          value={downPayment}
          onChange={handleDownPaymentChange}
          min={MIN_DOWNPAYMENT_VALUE}
          max={MAX_DOWNPAYMENT_VALUE}
        />
      </div>

      <div className="section">
        <TabContent>
          <TabPane>
            <div className="title flex p-2 align-items-end">
              Average Market Rent
              <Tooltip context="Rent" />
              <span>
                $
                {displayNumber(
                  estimatedMarketRent != null ? estimatedMarketRent : 0
                )}
              </span>
            </div>
            <div className="title flex align-items-end">
              Expected Rent
              <Tooltip context="Rent" />
              <span>
                ${displayNumber(rentData != null ? rentData.mean : 0)}
              </span>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default PriceParams;
