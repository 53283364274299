/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Col, Container, NavLink, Row } from "reactstrap";
import React from "react";
import {
  mainContent,
  mainSection,
  bigButton,
  featuredPropertySection,
  baseFont,
  overviewSection,
  smallButton,
} from "./styles.css";
import { ApiClient } from "../../utils/apiClient";
import { SimpleListing } from "../../data-models/SimpleListing";
import HouseCard from "../../components/HouseCard";
import overviewImg3 from "./images/00fe98e7a185e30186eeb3a4ea5f6e75390172e3.jpg";
import overviewImg2 from "./images/c8ac8b07f2ccdc010e285c83864a27472032f7e2.jpg";
import overviewImg1 from "./images/3f5b53df6bd08e9c44bf5ea21bd96babc17d2985.jpg";

type HomePageProps = {};
type HomePageState = {
  listings: SimpleListing[];
};

class HomePage extends React.Component<HomePageProps, HomePageState> {
  private apiClient: ApiClient;

  // private readonly listings: [SimpleListing[], Dispatch<SetStateAction<SimpleListing[]>>];

  constructor(props: HomePageProps) {
    super(props);
    this.apiClient = new ApiClient();
    this.state = {
      listings: [],
    };
    // this.listings = useState<SimpleListing[]>([]);
  }

  componentDidMount() {
    this.apiClient.fetchFeaturedListings().then((data) => {
      this.setState({
        listings: data,
      });
    });
  }

  render() {
    return (
      <div css={baseFont}>
        {/* Hero shot */}
        <section css={mainSection}>
          <Container>
            <div css={mainContent}>
              <h1>Build wealth through real estate</h1>
              <h4>
                Homevanna makes investing in single-family properties radically
                simple.
              </h4>
              <div>
                <NavLink href={"#"} role={"button"} css={bigButton}>
                  Learn More
                </NavLink>
              </div>
            </div>
          </Container>
        </section>

        {/* Property Grid */}
        <section css={featuredPropertySection}>
          <Container>
            <Row>
              {this.state.listings.map((value, index) => {
                //TODO - get real rating
                return (
                  <Col xs="12" sm="6" md="4" lg="3" key={index}>
                    <HouseCard
                      key={index}
                      address={value.address}
                      listingId={value.listingId}
                      images={value.images}
                      price={value.price}
                      bedroomsCount={value.bedroomsCount}
                      bathroomsCount={value.bathroomsCount}
                      size={value.size}
                      returnYears={1}
                      rating={5}
                    />
                  </Col>
                );
              })}
            </Row>
          </Container>
          <div>
            <NavLink href={"#"} role={"button"} css={bigButton}>
              View All Properties
            </NavLink>
          </div>
        </section>

        {/* Overview */}
        <section css={overviewSection}>
          <div className="container">
            <div className="title">
              <h2>Simple. Fast. Smart.</h2>
              <p>Quick overview of how Homevanna works.</p>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <div className="sfs_main">
                  <div className="sfs_img">
                    <img src={overviewImg1} alt="overview1" />
                  </div>
                  <div className="sfs_content">
                    <h3>Completed Transactions</h3>
                    <p>
                      More than 1$ billion in completed SFR transactions in less
                      than two years
                    </p>
                    <NavLink href={"#"} role={"button"} css={smallButton}>
                      Learn More
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="sfs_main">
                  <div className="sfs_img">
                    <img src={overviewImg2} alt="overview2" />
                  </div>
                  <div className="sfs_content">
                    <h3>Completed Transactions</h3>
                    <p>
                      More than 1$ billion in completed SFR transactions in less
                      than two years
                    </p>
                    <NavLink href={"#"} role={"button"} css={smallButton}>
                      Learn More
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="sfs_main">
                  <div className="sfs_img">
                    <img src={overviewImg3} alt="overview3" />
                  </div>
                  <div className="sfs_content">
                    <h3>Completed Transactions</h3>
                    <p>
                      More than 1$ billion in completed SFR transactions in less
                      than two years
                    </p>
                    <NavLink href={"#"} role={"button"} css={smallButton}>
                      Learn More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export { HomePage };
