/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import {
  Button,
  ButtonToggle,
  Col,
  Container,
  Row,
  UncontrolledCollapse,
} from "reactstrap";
import { filterHeader, moreFilterButton, moreFilters } from "./styles.css";
import {
  applyFilterButton,
  moreFilterAlt,
  moreFilterBase,
} from "../Home/styles.css";
import {
  FilterBedBath,
  FilterPropertyType,
} from "./Filters/BedBathPropertyType";
import { ListingDuration, ListingStatus } from "./Filters/StatusDuration";
import { SizeAndYear } from "./Filters/SizeAndYear";
import { SchoolRating } from "./Filters/SchoolFilter";
import { HomeAmenities } from "./Filters/HomeAmenities";
import { DropDownItemProps, GenerateUncontrolledDropDown } from "./Util";
import numeral from "numeral";

interface FilterHeaderProps {
  filterSelected?: (key: string, value: string) => void;
}

function FilterHeader(props: FilterHeaderProps) {
  let prices: DropDownItemProps[] = [];
  for (let i = 1; i < 10; i++) {
    prices.push({ value: i * 100000, display: `\$${i * 100},000` });
  }
  for (let i = 0; i < 10; i++) {
    prices.push({
      value: (1 + i * 0.5) * 1000000,
      display: `\$${1 + i * 0.5} Million`,
    });
  }
  return (
    <Row className={"align-items-center my-lg-3"}>
      <Col className={"d-flex flex-wrap justify-content-between"}>
        <div className={"d-flex flex-wrap justify-content-between "}>
          <GenerateUncontrolledDropDown
            id={"priceStart"}
            options={[{ value: null, display: "No Min" }].concat(prices)}
            optionSelected={(value) => {
              if (props.filterSelected != null) {
                props.filterSelected("priceStart", value.value);
              }
            }}
          />
          <span className={"mx-md-3 mx-1"}>to</span>
          <GenerateUncontrolledDropDown
            id={"priceEnd"}
            options={[{ display: "No Max", value: null }].concat(prices)}
            optionSelected={(value) => {
              if (props.filterSelected != null) {
                props.filterSelected("priceEnd", value.value);
              }
            }}
          />
        </div>
        <ButtonToggle id={"moreFiltersToggle"} css={moreFilterButton}>
          More Filters
        </ButtonToggle>
      </Col>
    </Row>
  );
}

type FilterProps = {
  numberOfResults: number;
  showingStart: () => void;
  hidingStart: () => void;
};

export function FilterPanel(props: FilterProps) {
  return (
    <Container>
      <Row xs={1} css={filterHeader}>
        <Col>Price</Col>
      </Row>
      <FilterHeader />
      <Row>
        <Col xs={12} className={"position-absolute"}>
          <UncontrolledCollapse
            toggler={"moreFiltersToggle"}
            css={moreFilters}
            onEntering={props.showingStart}
            onExiting={props.hidingStart}
          >
            <Row css={moreFilterBase}>
              <Col xs={12}>
                <FilterBedBath />
                <FilterPropertyType />
              </Col>
            </Row>
            {/*end section 1*/}
            <ListingStatus />
            <ListingDuration />
            <SizeAndYear />
            <SchoolRating />
            <HomeAmenities />
            <Row css={moreFilterAlt}>
              <Col
                className={
                  "d-flex flex-wrap align-items-center justify-content-around"
                }
              >
                <Button className="reset-btn">Reset</Button>
                <span>{numeral(props.numberOfResults).format("0,0")} Home</span>
                <Button css={applyFilterButton}>Apply Filters</Button>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </Col>
      </Row>
    </Container>
  );
}
