/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { Card, CardBody, CardSubtitle, CardTitle, Container } from "reactstrap";
import numeral from "numeral";
import { marker } from "./PropertyMarker.css";

export interface PropertyMapMarkerProp {
  image?: string;
  address: string;
  info: string;
  lat: number;
  lng: number;
  price: number;
  listingId: string;
  markerSelected: (listing: string) => void;
  $hover: boolean;
}

export interface PropertyMapMarkerState {}

export class PropertyMapMarker extends React.Component<
  PropertyMapMarkerProp,
  PropertyMapMarkerState
> {
  render() {
    return (
      <Card
        css={marker}
        style={{
          zIndex: this.props.$hover ? 1 : 0,
        }}
        className={"text-center"}
        onClick={() => {
          this.props.markerSelected(this.props.listingId);
        }}
      >
        <CardBody className={"p-1"}>
          <CardTitle tag={"h6"}>
            {numeral(this.props.price).format(
              this.props.price > 1000000 ? "$0.0a" : "$0a"
            )}
          </CardTitle>
          <CardSubtitle>{this.props.info}</CardSubtitle>
        </CardBody>
      </Card>
    );
  }
}
