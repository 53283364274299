/** @jsx jsx */
import { jsx } from "@emotion/core";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/index.css";
import logo from "../../images/logo_icon.png";
import { faBars, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Collapse,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import {
  collapsedMenu,
  navButton,
  navLink,
  headerSearchBox,
  headerSearchButton,
  mobileMenuToggle,
  desktopNavItem,
  navBarMobile,
  navBar,
  navBarScrolled,
  navBarLogoTextNormal,
  navBarLogoTextScrolled,
  StyledNavBarBrand,
} from "./styles.css";

import { useLayoutEffect, useState } from "react";
import { Redirect } from "react-router-dom";

const MainNavLink: ({ href, title }: { href: any; title: any }) => any = ({
  href,
  title,
}) => (
  <NavLink css={navLink} className={"hvr-underline-from-center"} href={href}>
    {title}
  </NavLink>
);

type HeaderProps = {
  onSearchBoxUpdate?: (query: string) => void;
};

function Header(props: HeaderProps) {
  const [scrolled, setScrolled] = useState(false);
  const [searchAddress, setSearchAddress] = useState("");
  const [collapsed, setCollapsed] = useState(true);
  const [searchSubmitted, setSearchSubmitted] = useState(false);

  useLayoutEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (document.documentElement.scrollTop > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const headerSearchBar = (
    <div css={headerSearchBox}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (searchAddress === "") {
            return;
          }
          setSearchSubmitted(true);
        }}
      >
        <InputGroup>
          <Input
            placeholder={"Enter address, neighborhood, or zip code"}
            onChange={(e) => {
              setSearchAddress(e.target.value);
            }}
          />
          <InputGroupAddon addonType={"append"}>
            <Button
              css={headerSearchButton}
              onClick={() => {
                if (searchAddress !== "") {
                  setSearchSubmitted(true);
                }
              }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </Form>
    </div>
  );
  const brandIcon = (
    <StyledNavBarBrand>
      <NavLink
        css={scrolled ? navBarLogoTextScrolled : navBarLogoTextNormal}
        href={"/"}
      >
        <img src={logo} alt="logo" />
        <span>homevanna</span>
      </NavLink>
    </StyledNavBarBrand>
  );
  const navItems = (
    <Nav navbar>
      <NavItem>
        <MainNavLink href={"#buy"} title={"Buy"} />
      </NavItem>
      <NavItem>
        <MainNavLink href={"#sell"} title={"Sell"} />
      </NavItem>
      <NavItem>
        <MainNavLink href={"#own"} title={"Own"} />
      </NavItem>
      <NavItem>
        <MainNavLink href={"#blog"} title={"Blog"} />
      </NavItem>
      <NavItem className={"nav-border-left"}>
        <MainNavLink href={"#login"} title={"Login"} />
      </NavItem>
      <NavItem className={"nav-item-primary"}>
        <NavLink
          css={navButton}
          className={"btn btn-primary"}
          role={"button"}
          href={"#login"}
        >
          Sign Up
        </NavLink>
      </NavItem>
    </Nav>
  );
  if (searchAddress !== "" && searchSubmitted) {
    if (props.onSearchBoxUpdate != undefined) {
      setSearchSubmitted(false);
      props.onSearchBoxUpdate(`address=${searchAddress}`);
    } else {
      return <Redirect to={"/propertySearch?address=" + searchAddress} />;
    }
  }
  return (
    <header>
      <Navbar css={navBarMobile}>
        <Container>
          <Collapse
            navbar
            id={"collapsibleNavbar"}
            isOpen={!collapsed}
            css={collapsedMenu}
          >
            {brandIcon}
            <div className="navbar_mt_close">
              <Button type="button" onClick={toggleNavbar}>
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
            {navItems}
          </Collapse>
        </Container>
      </Navbar>

      <Navbar
        fixed={"top"}
        expand={"md"}
        css={scrolled ? navBarScrolled : navBar}
      >
        <Container>
          <div className="header_brand_search d-flex align-items-center">
            <NavbarToggler
              type={"button"}
              onClick={toggleNavbar}
              css={mobileMenuToggle}
            >
              <FontAwesomeIcon icon={faBars} />
            </NavbarToggler>
            {brandIcon}
            {headerSearchBar}
          </div>

          <Collapse navbar className={"mr-auto"} css={desktopNavItem}>
            {navItems}
          </Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export { Header };
