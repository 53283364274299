import { css } from "@emotion/core";

const baseFont = css({
  fontFamily: "'Source Sans Pro', sans-serif",
});

const color = {
  primary: "#66CCFF",
  secondary: "#FF9900",
};

const primaryButton = css({
  color: "#fff",
  backgroundColor: "#ff9900",
  borderColor: "#ff9900",
  fontSize: "20px",
  boxShadow: "-1px 1px 20px rgba(0, 0, 0, 0.22)",
  "&:hover": {
    color: "#fff",
  },
});

const basicLayout = css({
  "@media (min-width: 1200px)": {
    maxWidth: 1270,
  },
});

const baseSection = css(baseFont, {
  padding: "60px 0",
  minHeight: "700px",
  backgroundSize: "cover !important",
  backgroundPosition: "top left !important",
});

const baseDropdown = css(baseFont, {
  button: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    color: "#383f44",
    textAlign: "left",
    fontSize: "14px",
    padding: "3px 10px",
  },
});

export {
  baseFont,
  primaryButton,
  basicLayout,
  baseSection,
  baseDropdown,
  color,
};
