/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { moreFilterBase } from "../../Home/styles.css";
import { Col, Row } from "reactstrap";
import { GenerateUncontrolledDropDown } from "../Util";

export function SizeAndYear() {
  let years: any[] = ["None"];
  let date = new Date().getFullYear();
  for (let i = 0; i < 10; i++) {
    years.push(date - i);
  }
  let dateDistant = date - 10;
  for (let i = 0; i < 10; i++) {
    years.push(dateDistant - i * 10);
  }
  return (
    <Row css={moreFilterBase}>
      <Col>
        <Row className={"align-items-center"}>
          <Col>
            <h3>Property Facts</h3>
          </Col>
        </Row>
        <Row className={"align-items-center"}>
          <Col>
            <h5>Square Feet</h5>
          </Col>
        </Row>
        <Row>
          <Col className={"d-flex flex-wrap"}>
            <GenerateUncontrolledDropDown
              id={"minHouseSize"}
              options={[
                { display: "None", value: null },
                { display: "1000", value: 1000 },
                { display: "1500", value: 1500 },
                { display: "2000", value: 2000 },
                { display: "2500", value: 2500 },
                { display: "3000", value: 3000 },
                { display: "4000", value: 4000 },
                { display: "5000+", value: 5000 },
              ]}
            />
            <span style={{ margin: "0px 10px" }}>to</span>
            <GenerateUncontrolledDropDown
              id={"maxHouseSize"}
              options={[
                { display: "None", value: null },
                { display: "1000", value: 1000 },
                { display: "1500", value: 1500 },
                { display: "2000", value: 2000 },
                { display: "2500", value: 2500 },
                { display: "3000", value: 3000 },
                { display: "4000", value: 4000 },
                { display: "5000", value: 5000 },
              ]}
            />
          </Col>
        </Row>
        <Row className={"align-items-center"}>
          <Col>
            <h5>Lot Size</h5>
          </Col>
        </Row>
        <Row>
          <Col className={"d-flex flex-wrap"}>
            <GenerateUncontrolledDropDown
              id={"minLotSize"}
              options={[
                { display: "None", value: null },
                { display: "1000", value: 1000 },
                { display: "1500", value: 1500 },
                { display: "2000", value: 2000 },
                { display: "2500", value: 2500 },
                { display: "3000", value: 3000 },
                { display: "4000", value: 4000 },
                { display: "5000", value: 5000 },
              ]}
            />
            <span style={{ margin: "0px 10px" }}>to</span>
            <GenerateUncontrolledDropDown
              id={"maxLotSize"}
              options={[
                { display: "None", value: null },
                { display: "1000", value: 1000 },
                { display: "1500", value: 1500 },
                { display: "2000", value: 2000 },
                { display: "2500", value: 2500 },
                { display: "3000", value: 3000 },
                { display: "4000", value: 4000 },
                { display: "5000", value: 5000 },
              ]}
            />
          </Col>
        </Row>
        <Row className={"align-items-center"}>
          <Col>
            <h5>Year Built</h5>
          </Col>
        </Row>
        <Row>
          <Col className={"d-flex flex-wrap"}>
            <GenerateUncontrolledDropDown id={"minYearBuilt"} options={years} />
            <span style={{ margin: "0px 10px" }}>to</span>
            <GenerateUncontrolledDropDown id={"maxYearBuilt"} options={years} />
          </Col>
        </Row>
        <Row className={"align-items-center mt-2"}>
          <Col className={"d-flex flex-wrap align-items-center"}>
            <h5>Max HOA Fees</h5>
            <GenerateUncontrolledDropDown
              id={"maxHOAFees"}
              options={[
                { display: "No Max", value: null },
                { display: "100", value: 100 },
                { display: "200", value: 200 },
                { display: "300", value: 300 },
                { display: "400", value: 400 },
                { display: "500", value: 500 },
                { display: "600", value: 600 },
                { display: "700", value: 700 },
                { display: "800", value: 800 },
                { display: "900", value: 900 },
                { display: "1000", value: 1000 },
              ]}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
