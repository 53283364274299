import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import { Row, Col } from "reactstrap";
import PageHeader from "./PageHeader";
import SellingCard from "./SellingCard";
import PurchaseControls from "./PurchaseControls";
import {
  Summary,
  Financials,
  Analysis,
  BuyProcess,
  SimilarListing,
} from "./tabpanels";
import ContextWrapper from "../../utils/Context";

import "./style.scss";
import "../../css/layout.scss";
import "../../css/common.scss";
import "../../css/index.css";

function HousePage() {
  let { path, url, params } = useRouteMatch();
  return HousePageComp(path, url, params.listingId);
}

const HousePageComp = (path, url, listingId) => (
  <Router>
    <ContextWrapper listingId={listingId}>
      <div style={{ fontFamily: "'Source Sans Pro', 'sans-serif'" }}>
        <PageHeader />

        <div className="house-page container">
          <Row className="main-row narrow-gutter">
            <Col sm="12" md="12" lg="4">
              <SellingCard />
            </Col>
            <Col sm="12" md="12" lg="8">
              <PurchaseControls />
            </Col>
          </Row>

          <div className="page-nav">
            <NavLink to={`${url}/summary`}>Summary</NavLink>
            <NavLink to={`${url}/financials`}>Financials</NavLink>
            <NavLink to={`${url}/analysis`}>Analysis</NavLink>
            <NavLink to={`${url}/buy-process`}>Buy Process</NavLink>
            <NavLink to={`${url}/similar-listings`}>Similar Listings</NavLink>
          </div>

          <Switch>
            <Route path={`${url}/summary`}>
              <Summary />
            </Route>
            <Route path={`${url}/financials`}>
              <Financials />
            </Route>
            <Route path={`${url}/analysis`}>
              <Analysis />
            </Route>
            <Route path={`${url}/buy-process`}>
              <BuyProcess />
            </Route>
            <Route path={`${url}/similar-listings`}>
              <SimilarListing />
            </Route>

            <Redirect exact from={`${url}`} to={`${url}/summary`} />
          </Switch>
        </div>
      </div>
    </ContextWrapper>
  </Router>
);

export default HousePage;
