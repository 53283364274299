/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import logo from "./images/2ed1995497f1b88bfc631a948503b924cef73945.png";
import { footer, footerSection1, footerSection2 } from "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons";
import { Container } from "reactstrap";

type FooterProps = {};
type FooterState = {};

class Footer extends React.Component<FooterProps, FooterState> {
  render() {
    return (
      <footer css={footer}>
        <div css={footerSection1}>
          <Container>
            <div className="row">
              <div className="col-xl-2 col-lg-12">
                <div className="fs1">
                  <ul className="social_ul">
                    <li>
                      <a href="#">
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FontAwesomeIcon icon={faFacebook} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FontAwesomeIcon icon={faLinkedin} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FontAwesomeIcon icon={faPinterest} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 ">
                <div className="fs1">
                  <h4>Real Estate Markets</h4>

                  <div className="ftr_row d-flex">
                    <ul className="flex_w50">
                      <li>
                        <a href="#">Alabama Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Alaska Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Arizona Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Arkansas Real Estate</a>
                      </li>
                      <li>
                        <a href="#">California Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Colorado Real Estate</a>
                      </li>
                    </ul>
                    <ul className="flex_w50">
                      <li>
                        <a href="#">Connecticut Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Delaware Real Estate</a>
                      </li>
                      <li>
                        <a href="#">District of Columbia Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Florida Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Georgia Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Hawaii Real Estate</a>
                      </li>
                    </ul>
                  </div>

                  <div className="ftr_more">
                    <a href="#">More</a>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 ">
                <div className="fs1">
                  <h4>Popular Real Estate Markets</h4>

                  <div className="ftr_row d-flex">
                    <ul className="flex_w50">
                      <li>
                        <a href="#">Los Angeles Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Houston Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Chicago Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Miami Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Naples Real Estate</a>
                      </li>
                      <li>
                        <a href="#">San Antonio Real Estate</a>
                      </li>
                    </ul>
                    <ul className="flex_w50">
                      <li>
                        <a href="#">New York Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Las Vegas Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Philadelphia Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Cape Coral Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Detroit Real Estate</a>
                      </li>
                      <li>
                        <a href="#">Atlanta Real Estate</a>
                      </li>
                    </ul>
                  </div>

                  <div className="ftr_more">
                    <a href="#">More</a>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-lg-6 col-md-6 col-6">
                <div className="fs1">
                  <h4>Resources</h4>
                  <ul>
                    <li>
                      <a href="#">Homes For Sale</a>
                    </li>
                    <li>
                      <a href="#">Homevanna Valuations</a>
                    </li>
                    <li>
                      <a href="#">Homevanna Field Services</a>
                    </li>
                    <li>
                      <a href="#">Property Records & Values</a>
                    </li>
                    <li>
                      <a href="#">Lenders/Financial Institutions</a>
                    </li>
                    <li>
                      <a href="#">Title365</a>
                    </li>
                    <li>
                      <a href="#">Real Estate Brokers and Agents</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-2 col-lg-6 col-md-6 col-6">
                <div className="fs1">
                  <h4>Helpful Links</h4>
                  <ul>
                    <li>
                      <a href={"/about"}>About Us</a>
                    </li>
                    <li>
                      <a href={"/contact"}>Contact Us</a>
                    </li>
                    <li>
                      <a href="#">Disclosures</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div css={footerSection2}>
          <p>
            <span>
              &copy; 2020 Homevanna. All rights reserved. Homevanna LLC. Use of
              this Website constitutes acceptance of the{" "}
              <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.
              Apple and Google are owned by their respective owners. By
              searching you agree to the end user license agreement (TOU).{" "}
              <a href="#">Accessibility Statement.</a>
            </span>
            <img src={logo} alt="" />
          </p>
        </div>
      </footer>
    );
  }
}

export { Footer };
