/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { mainSection } from "../AboutUs/react-styles.css";
import { mainContent } from "../Home/styles.css";
import contactUsBackground from "./images/contact-us-header.jpg";
import { Container } from "reactstrap";
import "./styles.css";

type ContactUsProps = {};
type ContactUsState = {};

class ContactUs extends React.Component<ContactUsProps, ContactUsState> {
  render() {
    return (
      <div>
        <section css={mainSection(contactUsBackground)}>
          <Container>
            <div css={mainContent}>
              <h1>Contact Us </h1>
            </div>
          </Container>
        </section>

        <section className="contact-info">
          <Container>
            <div className="contact-info-inner">
              <div className="row">
                <div className="col-md-4">
                  <div className="text-box">
                    <h3>Seller Support</h3>
                    <p></p>
                    <p>
                      Monday - Friday, 7am to 7pm PT
                      <br /> Saturday, 12pm to 5pm PT
                    </p>
                    <span>844-400-9663</span>
                    <a href="#">Seller.Support@homevanna.com</a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-box">
                    <h3>Buyer Support</h3>
                    <p>
                      Monday - Friday, 7am to 7pm PT
                      <br /> Saturday, 12pm to 5pm PT
                    </p>
                    <span>844-400-9663</span>
                    <a href="#">Seller.Support@homevanna.com</a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-box">
                    <h3>General Inquiries</h3>
                    <p>
                      Monday - Friday, 7am to 7pm PT
                      <br /> Saturday, 12pm to 5pm PT
                    </p>
                    <span>844-400-9663</span>
                    <a href="#">Seller.Support@homevanna.com</a>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    );
  }
}

export { ContactUs };
