import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import * as serviceWorker from "./serviceWorker";
import { Header } from "./components/header/header";
import { HomePage } from "./pages/Home/home";
import { Footer } from "./components/Footer/footer";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import HousePage from "./pages/PropertyDetails";
import { AboutUs } from "./pages/AboutUs/about-us";
import { ContactUs } from "./pages/ContactUs/contact-us";
import { PropertySearch } from "./pages/PropertySearch/property-search";
import queryString from "query-string";

function PropertyPage() {
  let [newQueryString, setNewQueryString] = useState<string | undefined>(
    undefined
  );
  let location = useLocation();
  function searchBoxUpdated(query: string) {
    setNewQueryString(query);
  }
  return (
    <Fragment>
      <Header onSearchBoxUpdate={searchBoxUpdated} />
      <PropertySearch
        query={
          newQueryString == undefined
            ? queryString.parse(location.search)
            : queryString.parse(newQueryString)
        }
      />
      <Footer />
    </Fragment>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact={true} path={"/"}>
          <Header />
          <HomePage />
          <Footer />
        </Route>
        <Route path={"/listing/:listingId"}>
          <Header />
          <HousePage />
          <Footer />
        </Route>
        <Route path={"/about"}>
          <Header />
          <AboutUs />
          <Footer />
        </Route>
        <Route path={"/contact"}>
          <Header />
          <ContactUs />
          <Footer />
        </Route>
        <Route path={"/propertySearch"}>
          <PropertyPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App></App>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
