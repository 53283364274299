import { css } from "@emotion/core";
import { baseFont, color } from "../../css/common.css";

export const resultTile = css(baseFont, {
  height: "100%",
  margin: "5px",
  paddingBottom: "10px",
  cursor: "pointer",
  ".imageContainer": {
    overflow: "hidden",
    position: "relative",

    img: {
      maxWidth: "100% ",
      borderRadius: "4px 4px 0 0",
      height: "auto",
      objectFit: "cover",
    },
    ".imgFooter": {
      background: ["#eee", "linear-gradient(to bottom, transparent, #999)"],
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      right: 0,
      padding: "7px 10px",
      p: {
        color: "#fff",
        fontSize: "19px",
        marginBottom: 0,
      },
      ".favorite": {
        display: "inline-block",
        cursor: "pointer",
      },
    },
  },
  ".propertyInfo": {
    backgroundColor: "#fff",
    borderRadius: "0 0 4px 4px",
    marginTop: "6px 10px",
    padding: "5px",
    p: {
      fontSize: "14px",
      textAlign: "center",
      marginBottom: "2px",
    },
    ".outlineIcon": {
      strokeWidth: "20px",
      stroke: "#FFB153",
    },
    ".rentTitle": {
      fontSize: "12px",
    },
    ".price": {
      fontSize: "22px",
      fontWeight: 400,
      lineHeight: "28px",
      color: "#383F44",
    },
    ".rentAndRate": {
      borderBottom: "1px solid #D1E3F7",
      paddingBottom: "10px",
    },
    ".address": {
      padding: "6px 0px",
      fontSize: "14px",
      lineHeight: "19px",
      marginTop: "2px",
      marginBottom: 0,
      textOverflow: "ellipsis",
      maxHeight: "51px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "165px",
      color: "#383F44",
    },
  },
});

export const selectedColor = css({
  border: `5px solid ${color.secondary}`,
});
