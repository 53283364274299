/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { baseDropdown } from "../../../css/common.css";
import { moreFilterBase } from "../../Home/styles.css";
import { propertyTypeGrid } from "../styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function FilterBedBath() {
  return (
    <Row
      css={moreFilterBase}
      className={"align-items-center justify-content-between"}
    >
      <Col>
        <Row>
          <Col>
            <h5>Beds</h5>
          </Col>
        </Row>
        <Row>
          <Col className={"d-flex flex-wrap"}>
            <UncontrolledDropdown
              id={"priceEnd"}
              css={baseDropdown}
              onToggle={(event: React.KeyboardEvent | React.MouseEvent) => {
                console.log(event.target);
              }}
            >
              <DropdownToggle caret={true}>Min Price</DropdownToggle>
              <DropdownMenu>
                <DropdownItem>No Min</DropdownItem>
                <DropdownItem>No Min</DropdownItem>
                <DropdownItem>No Min</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <span style={{ margin: "0px 10px" }}>to</span>
            <UncontrolledDropdown
              id={"priceEnd"}
              css={baseDropdown}
              onToggle={(
                event: React.KeyboardEvent | React.MouseEvent,
                isOpen: boolean
              ) => {
                console.log(event.target);
              }}
            >
              <DropdownToggle caret={true}> Max Price</DropdownToggle>
              <DropdownMenu>
                <DropdownItem>No Max</DropdownItem>
                <DropdownItem>No Max</DropdownItem>
                <DropdownItem>No Max</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </Col>
      <Col xs={2}>
        <Row>
          <h5>Baths</h5>
        </Row>
        <Row>
          <UncontrolledDropdown
            id={"priceEnd"}
            css={baseDropdown}
            onToggle={(
              event: React.KeyboardEvent | React.MouseEvent,
              isOpen: boolean
            ) => {
              console.log(event.target);
            }}
          >
            <DropdownToggle caret={true}> 1+</DropdownToggle>
            <DropdownMenu>
              <DropdownItem>1+</DropdownItem>
              <DropdownItem>2+</DropdownItem>
              <DropdownItem>3+</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Row>
      </Col>
    </Row>
  );
}

export function FilterPropertyType() {
  return (
    <Row css={moreFilterBase}>
      <Col>
        <Row>
          <Col>
            <h5>Property Type</h5>
          </Col>
        </Row>
        <Row
          noGutters={true}
          css={propertyTypeGrid}
          className={"align-items-center"}
        >
          <Col xs={4}>
            <FontAwesomeIcon
              icon={{
                prefix: "fas",
                iconName: "home",
              }}
            />
            <span>Home</span>
          </Col>
          <Col xs={4}>
            <FontAwesomeIcon icon={["fas", "building"]} />
            <span>Condo</span>
          </Col>
          <Col xs={4}>
            <FontAwesomeIcon icon={["fas", "hotel"]} />
            <span>Townhouse</span>
          </Col>
        </Row>
        <Row noGutters={true} css={propertyTypeGrid}>
          <Col xs={4}>
            <FontAwesomeIcon icon={["fas", "city"]} />
            <span>Multi-Family</span>
          </Col>
          <Col xs={4}>
            <FontAwesomeIcon icon={["fas", "tree"]} />
            <span>Land</span>
          </Col>
          <Col xs={4}>
            <FontAwesomeIcon icon={["fas", "industry"]} />
            <span>Other</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
