import React, { useContext, useReducer, useState } from "react";
import { TabContent, TabPane, Nav, NavItem } from "reactstrap";
import _ from "lodash";
import classnames from "classnames";
import Tooltip from "../../../../components/Tooltip";
import { PageContext, displayNumber } from "../../../../utils";
import {
  calcCapRate,
  calcGrossYield,
  calcInvestmentPrice,
  calcLoanPaymentsValue,
  calcNetCashFlow,
  calcSalesProceed,
  getYearlyFinancialValues,
  getYearlyNetCashFlow,
  getYearTenNetCashFlow,
} from "../../../../utils/formulas";

import "./style.scss";

// this is the top panel next to pricing on listing detail page

const InvestParams = () => {
  const {
    rentData,
    expenses,
    propertyTaxes,
    appreciation,
    purchasePrice,
    downPayment,
    loanInterestRate,
    annualizedReturn,
    closingCosts,
    estImmediateCosts,
  } = useContext(PageContext)[0];

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // const { expectedRent } = useContext(PageContext)[0];
  let rent = rentData == null ? 0 : rentData.mean;
  //TODO - figure out how to get rent into annualized return
  const grossYield = calcGrossYield({
    expectedRent: rent,
    purchasePrice: purchasePrice,
  });
  const loanPayments =
    downPayment === 100
      ? 0
      : calcLoanPaymentsValue({ purchasePrice, downPayment, loanInterestRate });

  const cashFlow = () => {
    let rent = rentData == null ? 0 : rentData.mean;
    return _.round(
      calcNetCashFlow(rent, expenses, propertyTaxes, loanPayments)
    );
  };

  const capRate = () => {
    let rent = rentData == null ? 0 : rentData.mean;
    return calcCapRate(
      rent,
      expenses,
      propertyTaxes,
      purchasePrice,
      loanPayments
    );
  };

  //TODO - refactor this along with TotalReturn/index.js
  let year1,
    year5,
    year10,
    year20,
    year30,
    currentTotalCashflow = 0;
  for (let i = 1; i <= 30; i++) {
    let year = getYearlyFinancialValues(i, {
      propertyTaxes,
      rentData,
      expenses,
    });
    currentTotalCashflow += calcNetCashFlow(
      year.expectedMonthlyRent,
      year.yearlyExpenses,
      year.yearlyPropertyTaxes,
      loanPayments
    );
    if (i == 5) {
      year5 = currentTotalCashflow;
    }
    if (i == 1) {
      year1 = currentTotalCashflow;
    }
    if (i == 10) {
      year10 = currentTotalCashflow;
    }
    if (i == 20) {
      year20 = currentTotalCashflow;
    }
    if (i == 30) {
      year30 = currentTotalCashflow;
    }
  }

  const salesProceed = calcSalesProceed({
    purchasePrice,
    downPayment,
    loanInterestRate,
    closingCosts,
    estImmediateCosts,
  });

  const totalInitialInvestment = _.round(
    calcInvestmentPrice({
      purchasePrice,
      downPayment,
      closingCosts,
      estImmediateCosts,
    })
  );
  //TODO - clean up with switch
  const totalReturn = () => {
    let year = year1;
    if (activeTab == "1") {
      year = year5;
    }
    if (activeTab == "2") {
      year = year10;
    }
    if (activeTab == "3") {
      year = year20;
    }
    if (activeTab == "4") {
      year = year30;
    }
    return year + salesProceed - totalInitialInvestment;
  };
  return (
    <div className="InvestParams">
      <TabContent>
        <TabPane>
          <div className="title flex">
            Total Return
            <Tooltip context="TotalReturn" />
            <span>${displayNumber(totalReturn(), true)}</span>
          </div>
        </TabPane>
      </TabContent>

      <Nav tabs justified className="upper-border">
        <NavItem
          className={classnames({ active: activeTab === "1" })}
          onClick={() => {
            toggle("1");
          }}
        >
          5 Yrs
        </NavItem>
        <NavItem
          className={classnames({ active: activeTab === "2" })}
          onClick={() => {
            toggle("2");
          }}
        >
          10 Yrs
        </NavItem>
        <NavItem
          className={classnames({ active: activeTab === "3" })}
          onClick={() => {
            toggle("3");
          }}
        >
          20 Yrs
        </NavItem>
        <NavItem
          className={classnames({ active: activeTab === "4" })}
          onClick={() => {
            toggle("4");
          }}
        >
          30 Yrs
        </NavItem>
      </Nav>

      <ul className="list">
        <li className="flex">
          <span>Annualized Return</span>
          <Tooltip context="AnnualizedReturn" />
          <span>{displayNumber(annualizedReturn)}%</span>
        </li>
        <li className="flex">
          <span>Cap Rate</span>
          <Tooltip context="CapRate" />
          <span>{capRate()}%</span>
        </li>
        <li className="flex">
          <span>Gross Yield</span>
          <Tooltip context="GrossYield" />
          <span>{grossYield}%</span>
        </li>
        <li className="flex">
          <span>Cash Flow</span>
          <Tooltip context="CashFlow" />
          <span>${displayNumber(cashFlow())}</span>
        </li>
        <li className="flex">
          <span>Appreciation</span>
          <Tooltip context="Appreciation" />
          <span>{appreciation}%</span>
        </li>
      </ul>
    </div>
  );
};

export default InvestParams;
