import _ from "lodash";
import {
  DEFAULT_ANNUAL_EXPENSE_INCREASE,
  DEFAULT_ANNUAL_PROPERTY_TAX_INCREASE,
  DEFAULT_ANNUAL_RENT_INCREASE,
} from "../config";

export const getMinPurchasePrice = (price) =>
  Math.floor((price / 1000) * 0.5) * 1000;
export const getMaxPurchasePrice = (price) =>
  Math.ceil((price / 1000) * 1.5) * 1000;

export const calcInvestmentPrice = ({
  purchasePrice,
  downPayment,
  closingCosts,
  estImmediateCosts,
}) => {
  const downPaymentCost = purchasePrice * (downPayment / 100);
  const borrowedAmount = purchasePrice - downPaymentCost;
  const loanFee = borrowedAmount * 0.01;

  return (
    downPaymentCost +
    purchasePrice * (closingCosts / 100) +
    estImmediateCosts +
    loanFee
  );
};

export const calcLoanPaymentsValue = ({
  purchasePrice,
  downPayment,
  loanInterestRate,
}) => {
  const numOfMonths = 30;
  const downPaymentCost = purchasePrice * (downPayment / 100);
  const principalAmount = purchasePrice - downPaymentCost;

  const p = principalAmount; //principle / initial amount borrowed
  const i = loanInterestRate / 100 / 12; //monthly interest rate
  const n = numOfMonths * 12; //number of payments months

  return (p * i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1);
};

// Net Operating Income ($) = Est. Expected Annual Rent – Est. Annual Expenses – Est. Annual Property Taxes - (Loan Payments x 12)
export const calcNetCashFlow = (
  expectedRent,
  expenses,
  propertyTaxes,
  loanPayments
) => {
  const rentNumber = expectedRent == null ? 0 : expectedRent;
  let cashFlow =
    Number(rentNumber) * 12 -
    Number(expenses) -
    Number(propertyTaxes) -
    Number(loanPayments) * 12;
  return cashFlow;
};

export function calcCapRate(
  expectedRent,
  expenses,
  propertyTaxes,
  purchasePrice,
  loanPayments
) {
  const netCashFlow = calcNetCashFlow(
    expectedRent,
    expenses,
    propertyTaxes,
    loanPayments
  );
  return _.round((netCashFlow / purchasePrice) * 100, 2);
}

export const calcGrossYield = ({ expectedRent, purchasePrice }) => {
  const rentNumber = expectedRent ?? 0;
  let number = _.round(((Number(rentNumber) * 12) / purchasePrice) * 100, 2);
  return number;
};

// Sales Proceed = (Purchase Price * 1.157) - (Purchase Price *1.157 * 0.05) - Loan Balance
export const calcSalesProceed = ({
  purchasePrice,
  downPayment = 0,
  loanInterestRate = 0,
  closingCosts,
  estImmediateCosts,
}) => {
  const loanBalance = calcLoanBalance({
    purchasePrice,
    downPayment,
    closingCosts,
    estImmediateCosts,
    loanInterestRate,
  });
  return _.round(
    purchasePrice * 1.157 - purchasePrice * 1.157 * 0.05 - loanBalance
  );
};

// LoanBalance = B = L[(1 + c)^n - (1 + c)^p]/[(1 + c)^n - 1]
const calcLoanBalance = ({
  purchasePrice,
  downPayment,
  closingCosts,
  estImmediateCosts,
  loanInterestRate,
  year = 5,
}) => {
  const p = {
    1: 12,
    3: 36,
    5: 60,
    10: 120,
  };
  const n = 360;

  const investmentPrice = calcInvestmentPrice({
    purchasePrice,
    downPayment,
    closingCosts,
    estImmediateCosts,
  });

  let loanAmount = investmentPrice - purchasePrice * (downPayment / 100);
  const trimNum = (num) => _.round(num, 12);

  const x =
    trimNum(Math.pow(1 + loanInterestRate, n)) -
    trimNum(Math.pow(1 + loanInterestRate, p[year]));
  const y = trimNum(Math.pow(1 + loanInterestRate, n)) - 1;
  return loanAmount * (x / y);
};

export const getYearlyFinancialValues = (
  year,
  { propertyTaxes, rentData, expenses }
) => {
  let expectedRent = rentData != null ? rentData.mean : 0;
  let yearActual = year - 1;
  return {
    yearlyPropertyTaxes: _.round(
      propertyTaxes *
        Math.pow(1 + DEFAULT_ANNUAL_PROPERTY_TAX_INCREASE, Number(yearActual))
    ),
    expectedMonthlyRent: _.round(
      expectedRent *
        Math.pow(1 + DEFAULT_ANNUAL_RENT_INCREASE, Number(yearActual))
    ),
    yearlyExpenses: _.round(
      expenses *
        Math.pow(1 + DEFAULT_ANNUAL_EXPENSE_INCREASE, Number(yearActual))
    ),
  };
};

//todo: refactor and extract annual calculation
export const getYearlyNetCashFlow = ({
  purchasePrice,
  downPayment,
  loanInterestRate,
  propertyTaxes,
  rentData,
  expenses,
}) => {
  const loanPayments = calcLoanPaymentsValue({
    purchasePrice,
    downPayment,
    loanInterestRate,
  });

  const year1 = getYearlyFinancialValues("1", {
    propertyTaxes,
    rentData,
    expenses,
  });
  const year3 = getYearlyFinancialValues("3", {
    propertyTaxes,
    rentData,
    expenses,
  });
  const year5 = getYearlyFinancialValues("5", {
    propertyTaxes,
    rentData,
    expenses,
  });

  const yearOneNetCashFlow = _.round(
    calcNetCashFlow(
      year1.expectedMonthlyRent,
      year1.yearlyExpenses,
      year1.yearlyPropertyTaxes,
      loanPayments
    )
  );

  const yearThreeNetCashFlow = _.round(
    calcNetCashFlow(
      year3.expectedMonthlyRent,
      year3.yearlyExpenses,
      year3.yearlyPropertyTaxes,
      loanPayments
    )
  );

  const yearFiveNetCashFlow = _.round(
    calcNetCashFlow(
      year5.expectedMonthlyRent,
      year5.yearlyExpenses,
      year5.yearlyPropertyTaxes,
      loanPayments
    )
  );

  return {
    yearOne: yearOneNetCashFlow,
    yearThree: yearThreeNetCashFlow,
    yearFive: yearFiveNetCashFlow,
  };
};

export const getYearTenNetCashFlow = (yearFive) => yearFive * 1.159;

export const calcEquityBuildUp = ({
  purchasePrice,
  downPayment,
  closingCosts,
  estImmediateCosts,
  loanInterestRate,
}) => {
  const loanBalance = (year) =>
    calcLoanBalance({
      purchasePrice,
      downPayment,
      closingCosts,
      estImmediateCosts,
      loanInterestRate,
      year,
    });

  return {
    yearOne: purchasePrice * 1.0314 - loanBalance(1),
    yearThree: purchasePrice * 1.0942 - loanBalance(3),
    yearFive: purchasePrice * 1.157 - loanBalance(5),
    yearTen: purchasePrice * 1.314 - loanBalance(10),
  };
};

export const calcCumAppreciationGain = ({ purchasePrice }) => ({
  yearOne: purchasePrice * 0.0314,
  yearThree: purchasePrice * 0.0942,
  yearFive: purchasePrice * 0.157,
  yearTen: purchasePrice * 0.314,
});

export const calcValuation = ({ price }) => ({
  low: price * 0.9,
  high: price * 1.033,
});
