import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { SimpleListing } from "./SimpleListing";

@jsonObject
export class PaginatedPropertiesResponse {
  @jsonArrayMember(SimpleListing)
  properties: SimpleListing[];
  @jsonMember({ constructor: Number })
  totalRecords: number;
  @jsonMember({ constructor: String })
  nextLink: string;
  constructor() {
    this.properties = [];
    this.totalRecords = 0;
    this.nextLink = "";
  }
}
