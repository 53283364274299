/** @jsx jsx */
import { moreFilterBase } from "../../Home/styles.css";
import { CustomCheckbox } from "../CustomCheckbox";
import { Col, Input, InputGroup, Row } from "reactstrap";
import { jsx } from "@emotion/core";
import { GenerateUncontrolledDropDown } from "../Util";

export function HomeAmenities() {
  return (
    <Row css={moreFilterBase}>
      <Col>
        <Row>
          <Col>
            <h3>Home Amenities</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Parking Spaces</h5>
          </Col>
        </Row>
        <Row>
          <Col className={"d-flex flex-column"}>
            <GenerateUncontrolledDropDown
              id={"parkingSpaces"}
              options={[
                { value: null, display: "No min" },
                { value: 1, display: "1" },
                { value: 2, display: "2" },
                { value: 3, display: "3" },
                { value: 4, display: "4+" },
              ]}
            />
            <CustomCheckbox id={"checkboxHaveView"} label={"Have View"} />
            <CustomCheckbox id={"checkboxBasement"} label={"Have Basement"} />
            <CustomCheckbox id={"checkboxWaterfront"} label={"Waterfront"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Story</h5>
          </Col>
        </Row>
        <Row>
          <Col className={"d-flex flex-wrap"}>
            <GenerateUncontrolledDropDown
              id={"minLevel"}
              options={[
                { value: 1, display: "1" },
                { value: 2, display: "2" },
                { value: 3, display: "3+" },
              ]}
            />
            <span style={{ margin: "0px 10px" }}>to</span>
            <GenerateUncontrolledDropDown
              id={"maxLevel"}
              options={[
                { display: "No Max", value: null },
                { display: "1", value: 1 },
                { display: "2", value: 2 },
                { display: "3+", value: 3 },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomCheckbox
              id={"checkboxAccessible"}
              label={"Accessible Homes only"}
            />
          </Col>
        </Row>
        <Row>
          <Col className={"d-flex flex-column"}>
            <h5>Keyword</h5>
            <InputGroup>
              <Input placeholder={"e.g. office, fireplace, or guesthouse"} />
            </InputGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
