import { css } from "@emotion/core";
import { baseFont } from "../../css/common.css";

export const marker = css(baseFont, {
  width: "7rem",
  backgroundColor: "#fff",
  borderRadius: "0.4rem",
  position: "relative",
  transform: "translate(-50%,-75%)",
  cursor: "pointer",
  "&: after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: "50%",
    width: 0,
    height: 0,
    border: "16px solid transparent",
    borderTopColor: "#fff",
    borderBottom: 0,
    borderLeft: 0,
    marginLeft: "-8px",
    marginBottom: "-16px",
  },
});
