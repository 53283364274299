import { css } from "@emotion/core";
import { baseFont, basicLayout } from "../../css/common.css";

const footer = css(baseFont, {
  margin: 0,
  padding: 0,
});

const footerSection1 = css({
  padding: "40px 0",
  ".social_ul": {
    li: {
      display: "inline-block",
      padding: "0 5px",
      svg: {
        fontSize: 20,
      },
    },
  },
  h4: {
    fontSize: 19,
    marginBottom: 5,
  },
  ul: {
    margin: 0,
    listStyle: "none",
    padding: "0 2px",
  },
  ".ftr_row": {
    margin: "0 -2px",
  },
  "ul li *": {
    lineHeight: "19px",
    color: "#939aa0",
    fontSize: "13px",
    display: "inline-block",
    ":hover": {
      color: "#ff9900",
    },
  },
  ".flex_w50": {
    "-ms-flex": "0 0 50%",
    flex: "0 0 50%",
    maxWidth: "50%",
  },
  ".ftr_more *": {
    color: "#3a9fd1",
  },
});

const footerSection2 = css({
  background: "#e1e5e8",
  padding: "30px 0",
  a: {
    color: "#3a9fd1",
  },
  p: {
    color: "#939aa0",
    margin: 0,
    textAlign: "center",
    paddingRight: 120,
    position: "relative",
    img: {
      maxWidth: "45px",
      position: "absolute",
      right: "15px",
      top: "50%",
      transform: "translateY(-50%)",
      opacity: 0.3,
    },
  },
});

export { footer, footerSection1, footerSection2 };
