import { css } from "@emotion/core";
import headerBackground from "../../images/homepage-header-with-overlay.jpg";
import { baseSection, primaryButton } from "../../css/common.css";
import { baseFont } from "../../css/common.css";

const homeBackground = css({
  backgroundColor: "#dfedfb",
});

const mainSection = css(baseFont, baseSection, {
  background: `url(${headerBackground})`,
});

const mainContent = css({
  paddingTop: "120px",
  color: "white",
  textAlign: "center",
  h1: {
    fontSize: "63px",
    marginBottom: "30px",
  },
  h4: {
    fontSize: "31px",
    maxWidth: "600px",
    margin: "auto",
    marginBottom: "20px",
  },
});

const bigButton = css(primaryButton, {
  display: "inline-block",
  width: "245px",
  margin: "30px 0",
});

const smallButton = css(primaryButton, {
  width: "auto",
  display: "inline-block",
  textTransform: "capitalize",
});

const featuredPropertySection = css(baseFont, homeBackground, {
  padding: 25,
  textAlign: "center",
  ".row": {
    textAlign: "left",
    "& > [class*=col-]": {
      paddingRight: "8px",
      paddingLeft: "8px",
    },
    ".flex": {
      display: "flex",
    },
    "& button": {
      border: 0,
    },
  },
  ".HouseCard": {
    marginBottom: "16px",
    cursor: "pointer",
  },
  "> .container": {
    marginTop: -190,
  },
  ".content": {
    backgroundColor: "#fff",
  },
});

const overviewSection = css(homeBackground, {
  paddingBottom: 100,
});

const moreFilterBase = css(baseFont, {
  padding: "10px",
  h5: {
    fontSize: "18px",
    fontWeight: "normal",
    marginTop: "0.5rem",
    marginRight: "0.5rem",
  },
  h3: {
    textTransform: "uppercase",
    fontSize: "19px",
    fontWeight: 300,
  },
  backgroundColor: "#fff",
});

const moreFilterAlt = css(moreFilterBase, {
  backgroundColor: "#F4F4F4",
});

const applyFilterButton = css({
  backgroundColor: "#FF9900",
  borderColor: "#FF9900",
  margin: "8px 3px",
  textTransform: "uppercase",
  "&:hover": {
    background: "#f29c1a",
    borderColor: "#f29c1a",
  },
});

export {
  mainSection,
  mainContent,
  bigButton,
  featuredPropertySection,
  baseFont,
  overviewSection,
  smallButton,
  moreFilterBase,
  moreFilterAlt,
  applyFilterButton,
};
