/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { Col, Nav, NavLink, Row } from "reactstrap";
import { resultTile, selectedColor } from "./ResultList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";

import img from "../Home/images/00fe98e7a185e30186eeb3a4ea5f6e75390172e3.jpg";
import { nav } from "./styles.css";
import { SimpleListing } from "../../data-models/SimpleListing";
import logo from "../../images/logo-name.svg";
import { useHistory } from "react-router-dom";

export type ResultListProp = {
  listings: SimpleListing[];
  totalPage: number;
  currentPage: number;
  nextPageHandler: () => void;
  skipPageHandler: (page: number) => void;
  selectedListingId?: string;
  didSelectListingTile?: (listingId: string) => void;
  isVisible: boolean;
};

export function ResultsList(prop: ResultListProp) {
  let history = useHistory();
  type PropertyTileProp = {
    listing: SimpleListing;
    selected: boolean;
  };

  function didSelectListing(listingId: string) {
    history.push(`/listing/${listingId}`);
  }

  function PropertyTile(prop: PropertyTileProp) {
    let listing = prop.listing;
    let formatter = new Intl.NumberFormat([], {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
    let img = <img src={listing.images ? listing.images[0] : ""} />;
    if (listing.images == null || listing.images.length == 0) {
      img = (
        <svg
          width={"100%"}
          height={"100%"}
          style={{ backgroundColor: "#fff", padding: "0 10%" }}
        >
          <image
            width={"100%"}
            height={"100%"}
            href={logo}
            style={{ padding: "10px" }}
          />
        </svg>
      );
    }
    let isSelected = prop.selected ? selectedColor : "";
    return (
      <div
        css={resultTile}
        onClick={() => didSelectListing(prop.listing.listingId)}
      >
        <div className={"d-flex flex-column h-100"} css={isSelected}>
          <div className={"imageContainer flex-grow-1"}>
            {img}
            <div
              className={
                "imgFooter d-flex justify-content-between align-item-center"
              }
            >
              <p>{formatter.format(listing.price)}</p>
              <span className="favorite">
                <FontAwesomeIcon icon={faHeart} size={"1x"} color={"#fff"} />
              </span>
            </div>
          </div>
          <div className={"propertyInfo"}>
            <div className={"rentAndRate"}>
              <p style={{ overflow: "hidden" }}>
                {`${listing.bedroomsCount} bds | ${listing.bathroomsCount} ba | ${listing.size} Sq Ft`}
              </p>
              <div className={"d-flex justify-content-between rentTitle"}>
                <span>Current Rent</span>
                <span>Neighborhood:</span>
              </div>
              <div className={"d-flex justify-content-between"}>
                <span className="price">
                  <sup>$</sup> 1,002
                </span>
                <div className="rating">
                  <FontAwesomeIcon
                    icon={{ prefix: "fas", iconName: "star" }}
                    color={"#FFB153"}
                    size={"xs"}
                  />
                  <FontAwesomeIcon
                    icon={{ prefix: "fas", iconName: "star" }}
                    color={"#FFB153"}
                    size={"xs"}
                  />
                  <FontAwesomeIcon
                    icon={{ prefix: "fas", iconName: "star" }}
                    color={"#FFB153"}
                    size={"xs"}
                  />
                  <FontAwesomeIcon
                    icon={{ prefix: "fas", iconName: "star" }}
                    color={"#FFB153"}
                    size={"xs"}
                  />
                  <FontAwesomeIcon
                    icon={{ prefix: "fas", iconName: "star" }}
                    color={"#FFF"}
                    size={"xs"}
                    className={"outlineIcon"}
                  />
                </div>
              </div>
            </div>
            {/*card-content-top*/}
            <p className={"d-flex flex-column address align-items-start"}>
              <div>{listing.street}</div>
              <div>{`${listing.city}, ${listing.state} ${listing.zip}`}</div>
            </p>
          </div>
        </div>
      </div>
    );
  }

  let listings = prop.listings;
  let tiles = [];
  for (let i = 0; i < listings.length; i += 2) {
    let row = [];
    row.push(
      <Col xs={6}>
        <PropertyTile
          listing={listings[i]}
          selected={prop.selectedListingId == listings[i].listingId}
        />
      </Col>
    );
    if (listings.length > i + 1) {
      row.push(
        <Col xs={6}>
          <PropertyTile
            listing={listings[i + 1]}
            selected={prop.selectedListingId == listings[i + 1].listingId}
          />
        </Col>
      );
    } else {
      row.push(<Col xs={6} />);
    }
    tiles.push(<Row noGutters={true}>{row}</Row>);
  }
  let navLinks = [];
  let paginationVisibleCount = 8;
  let startPage = Math.max(1, prop.currentPage - paginationVisibleCount / 2);
  let endPage = startPage + paginationVisibleCount;
  for (let i = startPage; i <= endPage; i++) {
    navLinks.push({ pageNumber: i, isCurrent: i == prop.currentPage });
  }
  return (
    <Row className={prop.isVisible ? "visible" : "invisible"}>
      <Col>
        <Row>
          <Col>{tiles}</Col>
        </Row>

        {/*Pagination Navs*/}
        <Row>
          <Col className={""}>
            <Nav css={nav} className={"justify-content-center"}>
              {prop.currentPage > 1 && (
                <NavLink
                  onClick={() => {
                    prop.skipPageHandler(prop.currentPage - 1);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </NavLink>
              )}
              {navLinks.map((value, index) => {
                return (
                  <NavLink
                    className={value.isCurrent ? "active" : ""}
                    onClick={() => {
                      prop.skipPageHandler(value.pageNumber);
                    }}
                  >
                    {value.pageNumber}
                  </NavLink>
                );
              })}
              <NavLink
                onClick={() => {
                  prop.nextPageHandler();
                }}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </NavLink>
            </Nav>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
