import React, { useReducer, useEffect, createContext } from "react";
import reducer, {EVENTS} from "./reducer";
import { defaultContext } from "./defaultContext";
import { fetchListingsData } from "../index";

export const PageContext = createContext(defaultContext);

const ContextWrapper = ({ listingId, children }) => {
  const contextValue = useReducer(reducer, defaultContext);
  const dispatch = contextValue[1];

  useEffect(() => {
    fetchListingsData(listingId).then((data) =>
      dispatch({ type: EVENTS.HYDRATE_LISTINGS_DATA, payload: data })
    );
  }, []);

  return (
    <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>
  );
};

export default ContextWrapper;
