/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { moreFilterAlt } from "../../Home/styles.css";
import { Col, Row } from "reactstrap";
import { GenerateUncontrolledDropDown } from "../Util";

export function SchoolRating() {
  return (
    <Row css={moreFilterAlt} className="fill-cm-wrap">
      <Col>
        <Row
          className={
            "d-flex flex-wrap align-items-center justify-content-between"
          }
        >
          <h3 className={"mr-2 mt-2"}>School Rating</h3>
          <GenerateUncontrolledDropDown
            id={"schoolRating"}
            options={[
              { value: 1, display: "1" },
              { value: 2, display: "2" },
              { value: 3, display: "3" },
              { value: 4, display: "4" },
              { value: 5, display: "5" },
            ]}
          />
        </Row>
      </Col>
    </Row>
  );
}
