/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import { SimpleListing } from "../../data-models/SimpleListing";
import { Redirect } from "react-router-dom";
import { PropertyMapMarker } from "./PropertyMapMarker";
import { Coordinate } from "../../data-models/Shared";
export interface MapProps {
  listings?: SimpleListing[];
  selectedProperty?: (listing: string) => void;
}
export interface MapState {
  bounds: google.maps.LatLngBounds | null;
  markers: google.maps.Marker[];
  selectedListingId: string | null;
  mapKey: number;
}

export class GoogleMap extends React.Component<MapProps, MapState> {
  constructor(props: MapProps) {
    super(props);
    this.state = {
      bounds: null,
      markers: [],
      selectedListingId: null,
      mapKey: 0,
    };
  }

  markerClicked(listingId: string) {
    if (this.props.selectedProperty != null) {
      this.props.selectedProperty(listingId);
    }
  }

  private getBounds(): GoogleMapReact.NESWBounds {
    let ne: Coordinate = { lat: 0, lng: 0 };
    let sw: Coordinate = { lat: 0, lng: 0 };
    this.props.listings?.forEach((value, _) => {
      if (value.lat == 0 || value.lon == 0) {
        return;
      }
      if (ne.lat == 0) {
        ne = { lat: value.lat, lng: value.lon };
        sw = { lat: value.lat, lng: value.lon };
      } else {
        ne = {
          lat: Math.max(ne.lat, value.lat),
          lng: Math.max(ne.lng, value.lon),
        };
        sw = {
          lat: Math.min(sw.lat, value.lat),
          lng: Math.min(sw.lng, value.lon),
        };
      }
    });
    let bound: GoogleMapReact.NESWBounds = {
      ne: { lat: ne.lat, lng: ne.lng },
      sw: { lat: sw.lat, lng: sw.lng },
    };
    return bound;
  }

  render() {
    if (this.state.selectedListingId != null) {
      // useHistory().push(`listing/${this.state.selectedListingId}`);
      return <Redirect to={`listing/${this.state.selectedListingId}`} />;
    }

    let bound = this.getBounds();
    let { center, zoom } = fitBounds(bound, { width: 640, height: 380 });

    return (
      <div style={{ height: "100%", width: "100%" }}>
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals={true}
          bootstrapURLKeys={{ key: "AIzaSyD4Rv-VjNpJ9J91Z6kN5KjHx8VAlgfPeQo" }}
          options={{ zoomControl: true, panControl: true, scrollwheel: true }}
          center={center}
          zoom={zoom}
          key={this.state.mapKey}
          onGoogleApiLoaded={(map) => {}}
          onChildClick={(key, prop) => {}}
        >
          {this.props.listings?.map((listing, index) => {
            return (
              <PropertyMapMarker
                listingId={listing.listingId}
                price={listing.price}
                image={listing.images != null ? listing.images[0] : ""}
                address={listing.address}
                info={`${listing.bedroomsCount} bds | ${listing.bathroomsCount} ba | ${listing.size} Sq Ft`}
                lat={listing.lat}
                lng={listing.lon}
                markerSelected={(listingId) => {
                  this.markerClicked(listingId);
                }}
                $hover={false}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    );
  }
}
