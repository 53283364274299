import { SimpleListing } from "../data-models/SimpleListing";
import { TypedJSON } from "typedjson";
import { PaginatedPropertiesResponse } from "../data-models/PaginatedPropertiesResponse";
import { Serializable } from "typedjson/js/typedjson/types";

export class ApiClient {
  private readonly url: string;
  constructor() {
    this.url = "https://api.staging.homevanna.com";
  }
  fetchFeaturedListings = (): Promise<SimpleListing[]> => {
    const URL = `${this.url}/featuredProperties`;
    return new Promise((resolve, reject) => {
      fetch(URL).then((data) => {
        data
          .text()
          .then((result) => {
            let p1 = new TypedJSON(PaginatedPropertiesResponse);
            let arr = p1.parse(result);
            resolve(arr?.properties);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  };
  searchProperties = (query: {
    [key: string]: any;
  }): Promise<PaginatedPropertiesResponse> => {
    if (!query["limit"]) {
      query["limit"] = 8;
    }
    let pairs: string[] = [];
    Object.entries(query).forEach(([key, value]) => {
      pairs.push(`${key}=${value}`);
    });
    let queryString = pairs.join("&");
    const URL = `${this.url}/properties?${queryString}`;
    return new Promise<PaginatedPropertiesResponse>((resolve, reject) => {
      fetch(URL).then((data) => {
        data
          .text()
          .then((result) => {
            let parser = new TypedJSON(PaginatedPropertiesResponse);
            let listings = parser.parse(result);
            resolve(listings);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  };
}
