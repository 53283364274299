/** @jsx jsx */
import { jsx } from "@emotion/core";
import CustomInput from "reactstrap/lib/CustomInput";
import React, { ChangeEvent, Component } from "react";
import styled from "@emotion/styled";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css, SerializedStyles } from "@emotion/core";

interface CustomCheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

type CustomCheckboxState = {
  checkboxStyle: SerializedStyles;
  checkboxEmptyStyle: SerializedStyles;
};

const StyledCheckbox = styled(CustomInput)({
  "input ~ label.custom-control-label:before, input ~ label.custom-control-label:after": {
    display: "none",
  },
});

class CustomCheckbox extends Component<
  CustomCheckboxProps,
  CustomCheckboxState
> {
  private readonly baseCheckbox: SerializedStyles;
  private readonly baseCheckmark: SerializedStyles;

  constructor(props: CustomCheckboxProps) {
    super(props);
    this.baseCheckbox = css({
      width: "16px",
      height: "16px",
      border: "1px solid #888787",
      backgroundColor: "transparent",
      borderRadius: "3px",
      float: "left",
      marginTop: "5px",
    });
    this.baseCheckmark = css(this.baseCheckbox, {
      float: "left",
      fontWeight: 900,
      verticalAlign: "middle",
      color: "#656D72",
      padding: "1px",
    });
    this.state = {
      checkboxStyle: css(this.baseCheckmark, {
        display: "none",
      }),
      checkboxEmptyStyle: css(this.baseCheckbox, {
        display: "inline-block",
      }),
    };
  }
  updateCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      checkboxStyle: css(this.baseCheckmark, {
        display: event.target.checked ? "inline-block" : "none",
      }),
      checkboxEmptyStyle: css(this.baseCheckbox, {
        display: event.target.checked ? "none" : "inline-block",
      }),
    });
  };
  render() {
    return (
      <div>
        <div css={this.state.checkboxEmptyStyle}></div>
        <FontAwesomeIcon icon={faCheck} css={this.state.checkboxStyle} />
        <StyledCheckbox
          id={this.props.id}
          type={"checkbox"}
          label={this.props.label}
          onChange={this.updateCheckbox}
        />
      </div>
    );
  }
}
export { CustomCheckbox };
