import { css } from "@emotion/core";
import { baseFont } from "../../css/common.css";

const mainContent = css(baseFont, {
  paddingTop: "60px",
  background: ["blue", "linear-gradient(to bottom, #003399, 15%, #DFEDFB 30%)"],
});

const mapArea = css({
  position: "relative",
  paddingBottom: "40px",
  paddingTop: "15px",
  "::after": {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "400px",
    content: "",
    zIndex: -1,
    background: ["blue", "linear-gradient(to bottom, #004DB3, #DFEDFB)"],
    // "-webkit-gradient(linear, left top, left bottom, from(#004DB3), to(#DFEDFB)), linear-gradient(to bottom, #004DB3, #DFEDFB)",
  },
});

const mapWrap = css({
  display: "grid",
  gridTemplateColumns: "70% calc(30% - 20px)",
  gridGap: "20px",
});

const mapResultCount = css({
  marginBottom: "15px",
  p: {
    color: "#fff",
    fontSize: "17px",
    marginLeft: "90px",
  },
});

const filterHeader = css(baseFont, {
  color: "#fff",
  fontWeight: 400,
  fontSize: "20px",
});

const moreFilterButton = css(baseFont, {
  fontSize: "14px",
  padding: "3px 10px",
  backgroundColor: "#fff",
  color: "#000",
});

const moreFilters = css(baseFont, {
  backgroundColor: "#fff",
  zIndex: 999,
});

const propertyTypeGrid = css(baseFont, {
  color: "#656D72",
  fontSize: "14px",
  textAlign: "center",
  ".col-4": {
    border: "1px solid rgba(86,61,124,.2)",
    padding: "10px",
  },
  span: {
    display: "block",
    lineHeight: 1,
  },
});

const nav = css(baseFont, {
  a: { color: "#444", fontSize: "15px", cursor: "pointer" },
  ".active": {
    backgroundColor: "#f5ae41",
    borderRadius: "50%",
    cursor: "default",
  },
  padding: "2px 6px",
});

export {
  mainContent,
  mapArea,
  mapWrap,
  mapResultCount,
  filterHeader,
  moreFilters,
  moreFilterButton,
  propertyTypeGrid,
  nav,
};
