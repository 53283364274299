import React, { useContext } from "react";
import { PageContext, displayNumber } from "../../../../../utils";
import { calcValuation } from "../../../../../utils/formulas";

import "./style.scss";

const Valuation = () => {
  const { price, rentData } = useContext(PageContext)[0];

  const { high, low } = calcValuation({ price });

  return (
    <div className="Valuation card-box">
      <div className="card-title">Valuation</div>
      <div className="prop-value">Property Value</div>
      <div className="price-large">
        ${displayNumber(low, true)} - ${displayNumber(high, true)}
      </div>
      <div className="rental-value">Rental Value</div>
      <div className="price-large">
        ${displayNumber(rentData == null ? 0 : rentData.mean, true)}
      </div>

      <div className="see-more">
        See more in
        <a href="#">Property Valuation Report</a>
      </div>
    </div>
  );
};

export default Valuation;
