import {
  DEFAULT_DOWNPAYMENT_VALUE,
  DEFAULT_LOAN_INTEREST_RATE,
  DEFAULT_CLOSING_COST_PERCENTAGE,
  DEFAULT_EST_IMMEDIATE_COSTS_RATE,
} from "../config";
import {
  calcInvestmentPrice,
  calcLoanPaymentsValue,
  calcNetCashFlow,
  getYearlyFinancialValues,
  getYearlyNetCashFlow,
} from "../../utils/formulas";

export const EVENTS = {
  HYDRATE_LISTINGS_DATA: "HYDRATE_LISTINGS_DATA",
  HYDRATE_TRANSACTIONS_DATA: "HYDRATE_TRANSACTIONS_DATA",
  HYDRATE_SIMILAR_LISTINGS_DATA: "HYDRATE_SIMILAR_LISTINGS_DATA",
  PURCHASE_PRICE_CHANGE: "PURCHASE_PRICE_CHANGE",
  IMMEDIATE_COST_CHANGE: "IMMEDIATE_COST_CHANGE",
  DOWNPAYMENT_CHANGE: "DOWNPAYMENT_CHANGE",
  LOAN_INTEREST_RATE_CHANGE: "LOAN_INTEREST_RATE_CHANGE",
  CLOSING_COST_RATE_CHANGE: "CLOSING_COST_RATE_CHANGE",
  APPRECIATION_RATE_CHANGE: "APPRECIATION_RATE_CHANGE",
  EXPECTED_RENT_CHANGE: "EXPECTED_RENT_CHANGE",
  EXPENSES_CHANGE: "EXPENSES_CHANGE",
  SET_NET_CASH_FLOW: "SET_NET_CASH_FLOW",
  RESTORE_DEFAULT: "RESTORE_DEFAULT",
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case EVENTS.HYDRATE_LISTINGS_DATA:
      return {
        ...state,
        ...payload,
        estimatedMarketRent: payload.rentData.mean,
      };

    case EVENTS.HYDRATE_TRANSACTIONS_DATA:
      return {
        ...state,
        ...payload,
      };

    case EVENTS.HYDRATE_SIMILAR_LISTINGS_DATA:
      return {
        ...state,
        similarListings: payload,
      };

    case EVENTS.PURCHASE_PRICE_CHANGE:
      const propertyTaxes = payload * 0.012;
      console.log(`price update ${payload}`);
      return {
        ...state,
        purchasePrice: payload,
        propertyTaxes,
      };

    case EVENTS.IMMEDIATE_COST_CHANGE:
      return {
        ...state,
        estImmediateCosts: payload,
      };

    case EVENTS.DOWNPAYMENT_CHANGE:
      return {
        ...state,
        downPayment: payload,
      };

    case EVENTS.LOAN_INTEREST_RATE_CHANGE:
      return {
        ...state,
        loanInterestRate: payload,
      };

    case EVENTS.CLOSING_COST_RATE_CHANGE:
      return {
        ...state,
        closingCosts: payload,
      };

    case EVENTS.APPRECIATION_RATE_CHANGE:
      return {
        ...state,
        appreciation: payload,
      };

    case EVENTS.EXPECTED_RENT_CHANGE:
      return {
        ...state,
        rentData: { mean: payload },
      };

    case EVENTS.EXPENSES_CHANGE:
      return {
        ...state,
        expenses: payload,
      };

    case EVENTS.SET_NET_CASH_FLOW: {
      const {
        purchasePrice,
        downPayment,
        loanInterestRate,
        propertyTaxes,
        rentData,
        expenses,
        closingCosts,
        estImmediateCosts,
      } = state;

      const { yearOne, yearThree, yearFive } = getYearlyNetCashFlow({
        purchasePrice,
        downPayment,
        loanInterestRate,
        propertyTaxes,
        rentData,
        expenses,
      });

      let totalCashFlow = 0;
      let loan = calcLoanPaymentsValue({
        purchasePrice,
        downPayment,
        loanInterestRate,
      });

      //calculate annualized return
      let yearsHeld = 5;
      for (let i = 1; i <= yearsHeld; i++) {
        let year = getYearlyFinancialValues(i, {
          propertyTaxes,
          rentData,
          expenses,
        });
        totalCashFlow += calcNetCashFlow(
          year.expectedMonthlyRent,
          year.yearlyExpenses,
          year.yearlyPropertyTaxes,
          loan
        );
      }
      let totalInvestment = calcInvestmentPrice({
        purchasePrice,
        downPayment,
        closingCosts,
        estImmediateCosts,
      });

      let annualizedReturn =
        (totalCashFlow / totalInvestment / yearsHeld) * 100;

      return {
        ...state,
        annualizedReturn: annualizedReturn,
        netCashFlow: {
          yearOne,
          yearThree,
          yearFive,
        },
      };
    }

    case EVENTS.RESTORE_DEFAULT:
      const { price } = state;

      return {
        ...state,
        purchasePrice: price,
        downPayment: DEFAULT_DOWNPAYMENT_VALUE,
        loanInterestRate: DEFAULT_LOAN_INTEREST_RATE,
        closingCosts: DEFAULT_CLOSING_COST_PERCENTAGE,
        estImmediateCosts: DEFAULT_EST_IMMEDIATE_COSTS_RATE,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
