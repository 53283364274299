/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { baseDropdown } from "../../css/common.css";

export interface DropDownProps {
  id: string;
  options: DropDownItemProps[];
  optionSelected?: (option: DropDownItemProps) => void;
}

export type DropDownItemProps = {
  value: any | null;
  display: string;
};

export function GenerateUncontrolledDropDown(props: DropDownProps) {
  return (
    <UncontrolledDropdown
      css={baseDropdown}
      id={props.id}
      onToggle={(event: React.KeyboardEvent | React.MouseEvent) => {
        console.log(event.target);
      }}
    >
      <DropdownToggle caret={true}>
        {props.options.length > 0 ? props.options[0].display : ""}
      </DropdownToggle>
      <DropdownMenu>
        {props.options.map((value) => {
          return (
            <DropdownItem
              onClick={() => {
                if (props.optionSelected != null) {
                  props.optionSelected(value);
                }
              }}
            >
              {value.display}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
